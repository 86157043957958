import { ofType, ActionsObservable } from 'redux-observable';
import { map, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { ajax, AjaxResponse, AjaxError } from 'rxjs/ajax';
import { of } from 'rxjs';
import {
	FeedbackFormActions,
	SENDFEEDBACKDATA,
	SENDFEEDBACKDATAFAILURE,
	GETSTORELIST,
	GetRequestPayload
} from './types';
import { SendFeedbackDataSuccess, GetStoreListFailure, GetStoreListSuccess } from './actions';

const {
	REACT_APP_API_URL = '',
	REACT_APP_FEEDBACK_BASE_URL = '',
	REACT_APP_FEEDBACK_API = ''
} = process.env;
const FEEDBACKURL = REACT_APP_API_URL + REACT_APP_FEEDBACK_BASE_URL + REACT_APP_FEEDBACK_API;
/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
export const SendFeedbackDataEpic = (action$: ActionsObservable<FeedbackFormActions>): any =>
	action$.pipe(
		ofType(SENDFEEDBACKDATA),
		mergeMap((action) => {
			const ActionPayload = action.payload as any;
			return ajax({
				method: 'POST',
				url: FEEDBACKURL,
				body: JSON.stringify(ActionPayload),
				headers: { 'Content-Type': 'application/json; charset=utf-8' },
				async: true,
				crossDomain: true,
				withCredentials: true
			}).pipe(
				map((response: AjaxResponse) => SendFeedbackDataSuccess(response, action)),
				catchError((error: AjaxError) =>
					of({
						type: SENDFEEDBACKDATAFAILURE,
						payload: error,
						meta: action
					})
				)
			);
		})
	);

export const GetStoreListEpic = (action$: ActionsObservable<FeedbackFormActions>): any =>
	action$.pipe(
		ofType(GETSTORELIST),
		switchMap((action) => {
			const ActionPayload = action.payload as GetRequestPayload;
			return ajax
				.get(`${ActionPayload?.url}?${decodeURIComponent(ActionPayload.query)}`, {})
				.pipe(
					map((response: AjaxResponse) => GetStoreListSuccess(response, action)),
					catchError((error: AjaxError) => of(GetStoreListFailure(error, action)))
				);
		})
	);
